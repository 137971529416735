.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-left: auto;
    margin-right: auto;
}

.video-responsive iframe {
    height: 100%;
    width: 100%;
    position: absolute;
}
