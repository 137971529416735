.all-container {
    display: flex;
    flex-direction: column;
    max-width: 1250px;
    margin: auto;
}

.small-container {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
    .small-container {
        margin: -5px auto 10px auto;
    }
}

.small-container > div {
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    /*
    width: calc(50% - 2px);
    height: 150px;
    */
}

.big-div {
    width: 100%;
    height: 150px;
    line-height: 150px;
    margin-top: 15px;
}

@media screen and (min-width: 800px) {
    .all-container {
        flex-direction: row;
    }

    .small-container {
        flex-direction: column;
    }

    .small-container > div {
        width: 200px;
    }

    .big-div {
        flex-grow: 1;
        height: 585px;
        margin-top: 0;
    }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 585px;
    max-width: 97%;
}
