

.expand-button {
    font-family: Bahnschrift, Helvetica, sans-serif;
    font-size: 0.95em;
    color: #eeeeee;
    text-align: center;
    user-select: none;

    border: 2px double #eeeeee;
    padding-top: 3px;
    padding-bottom: 3px;
    display: block;
    width: 95px;

    margin: 10px auto -5px auto;
}

.expand-button:hover {
    cursor: pointer;
    background: #eeeeee;
    color: #000000;
    transition: background 0.18s ease-out;
    border: 2px solid #000000;
}

@media screen and (max-width: 900px) {
    .expand-button {
        display: none;
    }
}
