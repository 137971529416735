.keckzonebottombar {
    margin-top: 120px;
    color: #A9A9A9;
    font-family: sans-serif;
    font-size: 10pt;

    text-align: center;

    justify-content: center;
    align-items: center;
    cursor: auto;
    transform: translate(0px, -10px);
}

.keckzonebottombar:hover {
    color: #CACACA;
    margin-bottom: 20px;
}

.keckzonetext {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
}

.keckzonetext:hover {
    text-decoration: underline;
    color: #DBDBDB;
}

@media screen and (min-width: 800px) {
    .keckzonebottombar {
        margin-top: 20px;
        transform: translate(0px, 0px);
    }

    .keckzonetext {
        text-decoration: none;
        text-shadow: 2px 2px 0px #212121;
    }
}


.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
    z-index: 3;
    cursor: pointer;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #FFF;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}


div.innertext {

    display: grid;
    height: 100%;
    width: 90%;
    padding: 1rem;
    justify-content: center;
    align-items: center;

    color: #DBDBDB;

    text-align: justify;
    font-size: 6vh;
    font-family: sans-serif;
    text-shadow: 4px 4px 0px #212121;
    mix-blend-mode: screen;
    margin-top: 3.5vh;
}




.social-media {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: center;
}

.social-media a {
    position: relative;
    display: block;
    width: 40vw;
    height: 40vw;
    background: rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 10px;
    box-shadow: 5px -2px 10px #CACACA;
    transition: 0.3s;

    cursor: pointer;
}

.social-media a::before {
    content: "";
    position: absolute;
    top: -4vw;
    left: 2vw;
    background: rgba(255, 255, 255, 0.18);
    height: 10%;
    width: 100%;
    transform: skewX(-45deg);
    transition: 0.3s;
}
.social-media a::after {
    content: "";
    position: absolute;
    top: -2vw;
    left: 40vw;
    background: rgba(255, 255, 255, 0.18);
    height: 100%;
    width: 10%;
    transform: skewY(-45deg);
    transition: 0.3s;
}


@media screen and (min-width: 800px) {


    .social-media a {
        position: relative;
        display: block;
        width: 200px;
        height: 200px;
        background: rgba(0, 0, 0, 0.1);
        text-align: center;
        margin: 10px;
        box-shadow: 5px -2px 10px #CACACA;
        transition: 0.3s;

        cursor: pointer;
    }


    .social-media a::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 10px;
        background: rgba(125, 125, 125, 0.18);
        height: 10%;
        width: 100%;
        transform: skewX(-45deg);
        transition: 0.3s;
    }
    .social-media a::after {
        content: "";
        position: absolute;
        top: -10px;
        left: 200px;
        background: rgba(125, 125, 125, 0.18);
        height: 100%;
        width: 10%;
        transform: skewY(-45deg);
        transition: 0.3s;
    }
    .social-media a:hover {
        transform: translate(-10%, 10%);
        box-shadow: 10px 0px 35px #FFF;
        transition: 0.3s;
    }
    .social-media a:nth-child(1):hover {
        background: #FFF;
        transition: 0.3s;
        color: #000710;
    }
    .social-media a:nth-child(1):hover::after {
        background: #DBDBDB;
        transition: 0.3s;
    }
    .social-media a:nth-child(1):hover::before {
        background: #CACACA;
        transition: 0.3s;
    }

    .social-media a:nth-child(2):hover {
        background: #FFF;
        transition: 0.3s;
        color: #000710;
    }
    .social-media a:nth-child(2):hover::after {
        background: #DBDBDB;
        transition: 0.3s;
    }
    .social-media a:nth-child(2):hover::before {
        background: #CACACA;
        transition: 0.3s;
    }

    .social-media a:nth-child(3):hover {
        background: #cd201f;
        transition: 0.3s;
    }
    .social-media a:nth-child(3):hover .fa {
        color: #fff;
        transition: 0.3s;
    }
    .social-media a:nth-child(3):hover::after {
        background: #a41918;
        transition: 0.3s;
    }
    .social-media a:nth-child(3):hover::before {
        background: #d74c4b;
        transition: 0.3s;
    }

    .social-media a:nth-child(4):hover {
        background: #55acee;
        transition: 0.3s;
    }
    .social-media a:nth-child(4):hover .fa {
        color: #fff;
        transition: 0.3s;
    }
    .social-media a:nth-child(4):hover::after {
        background: #4489be;
        transition: 0.3s;
    }
    .social-media a:nth-child(4):hover::before {
        background: #76bcf1;
        transition: 0.3s;
    }
}


.social-media img {
    max-width: 50%;
    display: block;
    margin-left: 25%;
    margin-top: 25%;
}



.bottomText {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    text-align: center;
    font-family: sans-serif;
    max-width: 600px;
}

.bottomText a {
    cursor: pointer;
    text-shadow: 2px 2px 0px #212121;
    text-decoration: underline;
}
