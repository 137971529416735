.headerbar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;

    font-family: sans-serif;
    font-size: 30px;
    color: #DBDBDB;
    text-shadow: 4px 4px 0px #212121;

    margin-top: 10px;
}

.headerbar:hover {
    cursor: pointer;
    color: #FFF;
}

.headerbar img {
    max-width: 30px;
    margin-right: 5px;
}

.headerbar:hover img {
    -webkit-filter: drop-shadow(2px 2px 7px rgba(255, 255, 255, 0.75));
    filter: drop-shadow(2px 2px 7px rgba(255, 255, 255, 0.75));
}



.headerbar-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;

    font-family: sans-serif;
    font-size: 14px;
    color: #DBDBDB;
    text-shadow: 4px 4px 0px #212121;

    margin-bottom: 10px;
}
