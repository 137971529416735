.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.button-container{
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 15px 8px 7px 8px;
}

.startbutton {
    width: 120px;
    height: 120px;
    cursor: pointer;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    background: black;
    border: 1px solid white;
    box-shadow: 2px 2px 0 white,
                -2px -2px 0 white,
                -2px 2px 0 white,
                2px -2px 0 white;
    transition: 500ms ease-in-out;
}

.startbutton:focus {
    outline: none;
}

.startbutton:hover {
    box-shadow: 20px 5px 0 white, -20px -5px 0 white;
    border: 1px solid #CACACA;
}



.filterbutton {
    width: 120px;
    height: 120px;
    cursor: pointer;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    background: black;
    border: 1px solid white;
    box-shadow: 2px 2px 0 white,
    -1px -1px 0 white,
    -1px 2px 0 white,
    2px -1px 0 white;
    transition: 500ms ease-in-out;
}

.filterbutton:focus {
    outline: none;
}

.filterbutton:hover {
    box-shadow: 8px 5px 0 white;
    border: 1px solid #CACACA;
}
