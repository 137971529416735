
div.foundpapers {
    text-align: center;
    color:#DBDBDB;
    font-family:monospace;
}






ul.ins-imgs {
    list-style-type: none;
    padding: 0;
}

li.ins-imgs-li {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}




div.ins-imgs-img img {
    width: 900px;
    max-width: 100%;
    height: auto;
    outline: none;
    -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
}

div.ins-imgs-img img:hover {
    cursor: pointer;
}

div.ins-imgs-img-zoom img {
    width: 99.9%;
    outline: none;
    -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-in-out;
}

div.ins-imgs-img-zoom img:hover {
    cursor: pointer;
}



div.ins-imgs-label {
    padding-top: 5px;
    font: 9px sans-serif;
    color: #DBDBDB;
    transition: all 0.3s ease-in-out;
}

div.ins-imgs-label-zoom {
    padding-top: 5px;
    font: 14px sans-serif;
    color: #DBDBDB;
    transition: all 0.2s ease-in-out;
}

div.ins-imgs-img a:focus {
    outline: none;
}



.example-appear {
    transform: translate(0px, 1000px);
}

.example-appear.example-appear-active {
    transform: translate(0px, 0px);
    transition: transform .5s ease-in;
}
