div.menu-text {

    display: grid;
    height: 100%;
    width: 90%;
    padding: 1rem;
    justify-content: center;
    align-items: center;

    color: #DBDBDB;

    text-align: justify;
    font-size: 30pt;
    font-family: sans-serif;
    text-shadow: 4px 4px 0px #212121;
    mix-blend-mode: screen;
    animation: fadeIn 2.5s linear forwards;
}
